<template>

  <div :class="{flixFocus: focused}">
    <getLabel :data="data" :focus="focused" :key="focused" />
    <textarea @change="callback(input)" v-model="input" :placeholder="$t('message.pleaseFillOut')" type="data.type" :id="data.id" @focusin="focused = true" @focusout="focused = false" :class="{flixFocus: focused}"/>
    <div v-if="data.description" class="small" :class="{flixFocus: focused}">{{ data.description }}</div>
  </div>

</template>

<script>

import getLabel from '@/components/formBuilder/parts/label'

export default {

  props: { data: Object, callback: Function },
  components: { getLabel },

  data () {
    return {
      focused: false,
      input: this.data.value
    }
  }
}

</script>

<style lang="sass" scoped>
  @import '@/components/formBuilder/style/variables.sass'
  @import '@/components/formBuilder/style/form/label.sass'
  @import '@/components/formBuilder/style/form/text.sass'
</style>
